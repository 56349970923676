// import 'bootstrap/js/dist/modal';
import Modal from 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/collapse';
import Swiper from 'swiper';
import 'swiper/swiper-bundle.css';
import SwiperCore, { Navigation, Autoplay, Pagination } from 'swiper/core';


SwiperCore.use([Navigation, Autoplay, Pagination]);
const reviews = new Swiper('.reviews', {
    loop: true,
    spaceBetween: 45,
    slidesPerView: 1,
    centeredSlidesBounds: true,
    // centeredSlides: true,
    navigation: {
        nextEl: '#reviews .swiper-button-next',
        prevEl: '#reviews .swiper-button-prev',
    },
    breakpoints: {
        // when window width is >= 320px
        768: {
          slidesPerView: 2,
        },
        // when window width is >= 480px
        1200: {
          slidesPerView: 3,
          spaceBetween: 45
        },
        // // when window width is >= 640px
        // 640: {
        //   slidesPerView: 4,
        //   spaceBetween: 40
        // }
    }
});
const heroSlider = new Swiper('.hero-slider', {
    loop: true,
    speed: 400,
    autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
    },
    pagination: {
        el: ".hero-slider .swiper-pagination",
    },
    navigation: {
        nextEl: '.hero-slider .swiper-button-next',
        prevEl: '.hero-slider .swiper-button-prev',
    },
});


function initForms(){
    
    var cbModal = new Modal(document.getElementById('callbackModal'))
    
    let forms = document.querySelectorAll('form')
    Array.from(forms).forEach(form => {
        
        form.addEventListener('submit', sendFrom)    
    });

    function sendFrom (e) {
        e.preventDefault()
        if (e.target.checkValidity()) {
            grecaptcha.ready(function() {
                grecaptcha.execute('6Lfs_Z4aAAAAAMfqpfgoYoG8MRQrqYM9tXKMeks1', {action: 'form'}).then(function(token) {
                    var gtokenInput = e.target.querySelector('input[name=g-recaptcha-token]');
                    console.log(token)
                    gtokenInput.value = token;
                    sendRequest(e)
                    
                });
            });
            
        }
        // el.textContent = data.body
        // el.classList.toggle('show')
        // setTimeout(function(){
        //     el.classList.toggle('show')
        // }, 3000)
    }
    async function sendRequest(e) {
        const url = e.target.dataset.mail_action
        const body = new FormData(e.target)
        const response = await fetch(url, { method: 'POST', body: body })
        const data = await response.json()
        console.log(data);
        data.status == 200 ? successSend(e.target, data) : errorSend(e.target, data)
    }
    function successSend(form, data) {
        const success = document.createElement('div')
        success.classList.add('success-order')
        success.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-hand-thumbs-up" viewBox="0 0 16 16">
        <path d="M8.864.046C7.908-.193 7.02.53 6.956 1.466c-.072 1.051-.23 2.016-.428 2.59-.125.36-.479 1.013-1.04 1.639-.557.623-1.282 1.178-2.131 1.41C2.685 7.288 2 7.87 2 8.72v4.001c0 .845.682 1.464 1.448 1.545 1.07.114 1.564.415 2.068.723l.048.03c.272.165.578.348.97.484.397.136.861.217 1.466.217h3.5c.937 0 1.599-.477 1.934-1.064a1.86 1.86 0 0 0 .254-.912c0-.152-.023-.312-.077-.464.201-.263.38-.578.488-.901.11-.33.172-.762.004-1.149.069-.13.12-.269.159-.403.077-.27.113-.568.113-.857 0-.288-.036-.585-.113-.856a2.144 2.144 0 0 0-.138-.362 1.9 1.9 0 0 0 .234-1.734c-.206-.592-.682-1.1-1.2-1.272-.847-.282-1.803-.276-2.516-.211a9.84 9.84 0 0 0-.443.05 9.365 9.365 0 0 0-.062-4.509A1.38 1.38 0 0 0 9.125.111L8.864.046zM11.5 14.721H8c-.51 0-.863-.069-1.14-.164-.281-.097-.506-.228-.776-.393l-.04-.024c-.555-.339-1.198-.731-2.49-.868-.333-.036-.554-.29-.554-.55V8.72c0-.254.226-.543.62-.65 1.095-.3 1.977-.996 2.614-1.708.635-.71 1.064-1.475 1.238-1.978.243-.7.407-1.768.482-2.85.025-.362.36-.594.667-.518l.262.066c.16.04.258.143.288.255a8.34 8.34 0 0 1-.145 4.725.5.5 0 0 0 .595.644l.003-.001.014-.003.058-.014a8.908 8.908 0 0 1 1.036-.157c.663-.06 1.457-.054 2.11.164.175.058.45.3.57.65.107.308.087.67-.266 1.022l-.353.353.353.354c.043.043.105.141.154.315.048.167.075.37.075.581 0 .212-.027.414-.075.582-.05.174-.111.272-.154.315l-.353.353.353.354c.047.047.109.177.005.488a2.224 2.224 0 0 1-.505.805l-.353.353.353.354c.006.005.041.05.041.17a.866.866 0 0 1-.121.416c-.165.288-.503.56-1.066.56z"/>
      </svg> ${data.body}`
        form.append(success)
        setTimeout(function(){
            Array.from(form.elements).forEach(element => {
                element.tagName == "SELECT" ? element.selectedIndex = 0 : element.value = ''
            })
            success.remove()
            if (form.closest('.modal')?.id == "callbackModal") {
                cbModal.hide()
            }
        }, 3000)
    }
    function errorSend(form, data) {
        const error = document.createElement('div')
        error.classList.add('error-order')
        error.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-circle" viewBox="0 0 16 16">
        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
        <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"/>
      </svg>${data.body}`
        form.append(error)
        setTimeout(function(){
            error.remove()
        }, 3000)
    }

}
initForms()

const toTop = document.querySelector('.totop-btn')
let btnVisible = false 
document.addEventListener('scroll', () => {
    if(window.pageYOffset >= 200 && !btnVisible) {
        toTop.style.opacity = 1
        btnVisible = true
    }
    if(window.pageYOffset < 200 && btnVisible) {
        toTop.style.opacity = 0
        btnVisible = false
    }
    
})
